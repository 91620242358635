import './styles/style.scss';

import { Controller } from 'stimulus';
import { atcb_action } from 'add-to-calendar-button';

import { DDPTabItem, DDPStimulusConfig } from '../..';
import { throttle, debounce } from '../../utils';
import { DailyProgramEvent } from '../../../../../../shared/types/dailyProgram';
import { timeToString } from '../../../../../../shared/lib/time';

export class RootController extends Controller {
  static values = { tabs: Array, config: Object };
  static targets = [
    'body',
    'heading',
    'navbar',
    'mobileHeader',
    'mobileMenuOverlay',
    'langOverlay',
    'drawer',
    'tab',
    'mobileTab',
    'navlink',
    'gridBlock',
    'mobileBody',
    'mobilePage',
  ];

  configValue!: DDPStimulusConfig;
  tabsValue!: DDPTabItem[];

  bodyTarget!: HTMLElement; // the <body></body>
  headingTarget!: HTMLElement; // what would you like to do today?
  navbarTarget!: HTMLElement; // the navigation bar at the top
  drawerTarget!: HTMLElement; // the bottom drawer that reveals (white)
  mobileHeaderTarget!: HTMLElement; // the title part of the mobile nav
  mobileMenuOverlayTarget!: HTMLElement; // the mobile menu overlay menu
  langOverlayTarget!: HTMLElement; // the mobile lang overlay menu
  mobileBodyTarget!: HTMLElement; // the mobile body
  mobilePageTarget!: HTMLElement; // the mobile page
  tabTargets!: HTMLElement[]; // each individual tab (events, etc.)
  mobileTabTargets!: HTMLElement[]; // each individual tab (events, etc.)
  navlinkTargets!: HTMLElement[]; // li elements of navigation bar
  gridBlockTargets!: HTMLElement[];

  throttleNavBarTypeDecider = throttle(this.decideNavBarType, 200);
  debounceNavBarTypeDecider = debounce(this.decideNavBarType, 200);
  throttleGridBlockTypeDecider = throttle(this.decideGridBlockType, 200);

  isMobile() {
    return window.innerWidth <= 700;
  }

  updateTabVisibility() {
    this.mobileMenuOverlayTarget.classList.remove('show');

    const hash = window.location.hash.substring(1);
    let drawerVisible = false;

    this.tabTargets.forEach(el => {
      const tab = this.tabsValue.find(t => t.key === el.dataset.key);
      if (!tab) return;

      const hidden = tab.key !== hash;

      el.hidden = hidden;
      drawerVisible = drawerVisible || !hidden;

      if (!el.hidden) this.mobileHeaderTarget.innerHTML = tab.label;
    });

    this.navlinkTargets.forEach(el => {
      const tab = this.tabsValue.find(t => t.key === el.dataset.tabkey);
      if (!tab) return;

      const hidden = tab.key !== hash;

      if (!hidden) el.classList.add('active');
      else el.classList.remove('active');
    });

    this.drawerTarget.hidden = !drawerVisible;
    this.headingTarget.hidden = drawerVisible;
  }

  // each grid block can have one of two different styles based on how
  // much content the grid block holds. if it is only a bit, it can show
  // it on a black shadow near the bottom of the block. if it is a lot,
  // it defaults to a full block shadow with scrolling capabilities
  decideGridBlockType() {
    this.gridBlockTargets.forEach(block => {
      const textDiv = block.getElementsByClassName('grid-text')[0];

      if (!(textDiv instanceof HTMLElement)) return;

      if (textDiv.offsetHeight < block.offsetHeight / 1.5) {
        block.classList.add('grid-small');
      } else {
        block.classList.remove('grid-small');
      }
    });
  }

  decideNavBarType() {
    const scrollTop = window.scrollY;

    if (scrollTop > window.innerHeight / 4) {
      this.navbarTarget.classList.add('slim');
    } else {
      this.navbarTarget.classList.remove('slim');
    }
  }

  handleSelectBlock(e: MouseEvent) {
    const target = e.currentTarget;
    if (!(target instanceof Element)) return;

    if (target.classList.contains('active')) {
      target.classList.remove('active');
    } else {
      target.classList.add('active');
    }

    setTimeout(() => this.decideGridBlockType());
  }

  handleChangeDate(e: MouseEvent) {
    const target = e.currentTarget;
    if (!(target instanceof HTMLInputElement)) return;

    // FIXME: UGLY!!
    window.location.href = window.location.href.replace(/\d\d\d\d-\d\d-\d\d/i, target.value);
  }

  handleMobileMenuNavClick() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      window.location.reload();
    } else {
      this.mobileMenuOverlayTarget.hidden = false;
      setTimeout(() => {
        this.mobileMenuOverlayTarget.classList.add('show');
      });
    }
  }

  handleLangNavClick() {
    this.langOverlayTarget.hidden = false;
    setTimeout(() => {
      this.langOverlayTarget.classList.add('show');
    });
  }

  handleMobileMenuCloseClick() {
    this.mobileMenuOverlayTarget.classList.remove('show');
    setTimeout(() => {
      this.mobileMenuOverlayTarget.hidden = true;
    }, 500);
  }

  handleLangCloseClick() {
    this.langOverlayTarget.classList.remove('show');
    setTimeout(() => {
      this.langOverlayTarget.hidden = true;
    }, 500);
  }

  handleLangClick(e: MouseEvent) {
    this.handleLangCloseClick();

    const target = e.currentTarget;
    if (!(target instanceof HTMLElement)) return;

    const lang = target.dataset.langkey;
    let params = new URLSearchParams(window.location.search);

    if (lang) params.set('lang', lang);
    else params.delete('lang');

    window.location.search = params.toString();
  }

  handleAddToCalendarClick(e: MouseEvent) {
    e.stopPropagation();

    if (!this.configValue.timezone) return;

    const target = e.currentTarget;

    if (!(target instanceof HTMLElement)) return;

    const event = JSON.parse(target.dataset.value!) as DailyProgramEvent;

    const eventDescriptionCta = event.callToAction
      ? `${this.configValue.ctaLabel} → [url]${event.callToAction}[/url]<br>`
      : '';

    const startTime = timeToString(event.startTime, true);
    let endTime = startTime;

    switch (event.interactiveFeedDisplay) {
      case 'show-finish-time':
        endTime = timeToString(event.endTime, true);
        break;
    }

    atcb_action(
      {
        name: event.title,
        description: `${eventDescriptionCta}${event.description}`,
        startDate: this.configValue.dateISO,
        endDate: this.configValue.dateISO,
        startTime,
        endTime,
        location: event.venue,
        options: ['Apple', 'Google', 'Outlook.com', 'iCal'],
        listStyle: this.isMobile() ? 'modal' : 'dropdown',
        iCalFileName: 'Reminder-Event',
        timeZone: this.configValue.timezone,
        trigger: 'click',
      },
      target
    );
  }

  redirectIfHomePage() {
    const hash = window.location.hash.substring(1);

    if (hash) return; // not at home page, no need to redirect

    if (this.isMobile()) {
      // mobile must always force open a tab
      window.location.href = this.configValue.onHomeUrl || `#${this.tabsValue[0].key}`;
    } else if (this.configValue.onHomeUrl) {
      // web will only force open a tab if it has to
      window.location.href = this.configValue.onHomeUrl;
    }
  }

  onWindowResize() {
    this.throttleGridBlockTypeDecider();
    this.redirectIfHomePage();
  }

  onHashChange() {
    this.updateTabVisibility();
    this.redirectIfHomePage();
    this.handleMobileMenuCloseClick();

    setTimeout(() => this.throttleGridBlockTypeDecider());
  }

  onScrollChange() {
    this.throttleNavBarTypeDecider();
    this.debounceNavBarTypeDecider();
  }

  connect() {
    this.bodyTarget.hidden = false;

    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.mobilePageTarget.classList.add('pwa');
    }

    if (this.mobileBodyTarget instanceof Element) {
      this.mobileBodyTarget.style.minHeight = `${window.innerHeight}px`;
    }

    window.addEventListener('resize', () => this.onWindowResize());
    window.addEventListener('hashchange', () => this.onHashChange());
    window.addEventListener('scroll', () => this.onScrollChange());

    this.onWindowResize();
    this.onHashChange();
    this.onScrollChange();
  }

  disconnect() {
    window.removeEventListener('resize', () => this.onWindowResize());
    window.removeEventListener('hashchange', () => this.onHashChange());
    window.removeEventListener('scroll', () => this.onScrollChange());
  }
}
