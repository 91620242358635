export type StringDict = { [k: string]: string };

export function throttle(func: () => void, limit: number): () => any {
  let inThrottle: boolean;

  return function (this: any): any {
    const args = arguments;
    const context = this;

    if (!inThrottle) {
      inThrottle = true;
      // @ts-ignore
      func.apply(context, args);
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export function debounce(func: () => void, wait: number): () => any {
  let timeoutID: any;

  return function (this: any): any {
    clearTimeout(timeoutID);

    const args = arguments;
    const context = this;

    timeoutID = setTimeout(() => {
      // @ts-ignore
      func.apply(context, args);
    }, wait);
  };
}
